import React from "react";
import Fade from "react-reveal/Fade";
import { FormattedMessage } from "gatsby-plugin-intl";

export default function Dekiru() {
  return (
    <div className="d-content d-content--pad dekiru mt-6 pt-6">
      <Fade bottom duration={1500}>
        {/* empty div needed for desired cascade effect */}
        <div>
          <h2 className="title is-size-1 is-size-2-mobile has-text-centered primary-color-text">
            <FormattedMessage id="dekiru.title" />
          </h2>
          <p className="ingress ingress--padding has-text-centered">
            <FormattedMessage id="dekiru.text" />
          </p>
        </div>
      </Fade>
    </div>
  );
}

import React from "react";
import Fade from "react-reveal/Fade";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";

import GetIntlArrayObject from "../../utils/GetIntlArrayObject";

export default function Intro() {
  const intl = useIntl();
  const title = GetIntlArrayObject("intro.titleWord", intl.messages);

  const titleColor = (color: string) => {
    if (color === "green") {
      return "primary-color-text";
    }
    if (color === "white") {
      return "has-text-light";
    }
    return "";
  };

  return (
    <div className="intro pt-6 mt-6">
      <div className="intro-container">
        <div className="columns">
          <div className="column is-10-widescreen is-12-mobile">
            <div className="intro__text">
              <Fade top duration={1500}>
                <h2 className="title is-size-1 is-size-2-mobile">
                  <div className="intro__break-lines">
                    {title.map((t) => (
                      <span key={t.text} className={titleColor(t.color)}>
                        {t.text}
                      </span>
                    ))}
                  </div>
                </h2>
              </Fade>
              <Fade up delay={500} duration={1500}>
                <p className="ingress has-text-light">
                  <FormattedMessage id="intro.text" />
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  /* return (
    <div className="intro">
      <img alt="Logo" className="intro__logo" src="/Dekiru_Logo.svg" />
      <div className="container">
        <div className="columns">
          <div className="column is-10-widescreen is-12-mobile">
            <div className="intro__text">
              <Fade top duration={1500}>
                <h2 className="title is-size-1 is-size-2-mobile">
                  <div className="intro__break-lines">
                    {title.map((t) => (
                      <span key={t.text} className={titleColor(t.color)}>
                        {t.text}
                      </span>
                    ))}
                  </div>
                </h2>
              </Fade>
              <Fade up delay={500} duration={1500}>
                <p className="ingress has-text-light">
                  <FormattedMessage id="intro.text" />
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );*/
}

import React from "react";

import Dekiru from "../components/dekiru/dekiru";
import Intro from "../components/intro/intro";
import Layout from "../components/layout";

export default function Index() {
  return (
    <div>
      <Layout>
        <Intro />
        <Dekiru />
      </Layout>
    </div>
  );
}
